.spinner {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#ffffff 94%,#0000) top/11.5px 11.5px no-repeat,
           conic-gradient(#0000 30%,#ffffff);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 11.5px),#000 0);
    animation: spinner-c7wet2 1.2s infinite linear;
 }
 
 @keyframes spinner-c7wet2 {
    100% {
       transform: rotate(1turn);
    }
 }
.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  width: 100%;
  // pointer-events: none;
  // border-radius: 50%;
  // border: 10px solid #337ab7;
}
// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

.App-header {
  background-color: map-get($map: $bg-color, $key: primary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: map-get($map: $text-size, $key: xxxl);
  color: #337ab7;
  font-family: "Roboto, Helvetica, Arial, sans-serif";
}

.header {
  font-size: map-get($map: $text-size, $key: xl);
  font-family: "Roboto, Helvetica, Arial, sans-serif";
}

.App-link {
  color: #61dafb;
}

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

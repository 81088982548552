.status {
  text-align: center;
  padding: 40px;
  &__section {
    &__img {
      height: 250px;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

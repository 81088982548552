.custom-paragraph {
  display: block;
  font-family: "Roboto, Helvetica, Arial, sans-serif";
  font-weight: 500 !important;
  text-align: justify;
  color: #4d5156;
  padding-left: 0px;
  padding-top: 0px;
  width: 100%;
}

.footer {
  width: -webkit-fill-available;
  // background-color: #27374d;
  margin-top: 25px;
  border-top: 1px solid #e9e9e9;
  &__linkSection {
    padding: 50px 0px;
  }
  &__link {
    text-decoration: none;
    color: #888888;
    &:hover {
      font-weight: 600;
    }
  }
  &__linkHeader {
    color: #337ab7;
    font-weight: 600 !important;
  }
  &__copyright {
    background-color: #27374d;
    padding: 10px;
    &__link {
      margin-bottom: 0px !important;
      color: #d5d5d5;
      a {
        color: #d5d5d5;
        text-decoration: none;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}

.home {
  background-color: #fff;
  &__banner {
    background-image: url("../../assets/images/home-banner.jpg");
    &__inner {
      padding: 150px 50px;
      background-color: #000;
      opacity: 0.8;
      &__heading {
        color: #ddd !important;
        text-align: center;
        font-size: 36px !important;
      }
      &__para {
        color: #979797;
        text-align: center;
        font-size: 24px !important;
      }
    }
  }
  &__about {
    padding-bottom: 50px;
    padding-top: 25px;
  }
}

.login {
  &__div {
    width: 100% !important;
    &__form {
      width: 100% !important;
      &__section {
        padding: 15px;
      }
      &__submit {
        padding-top: 30px;
        padding-bottom: 5px;
        text-align: center;
      }
      &__create {
        margin: 0px;
        margin-top: 10px;
        span {
          color: #337ab7;
          font-weight: 600;
          cursor: pointer;
        }
      }
      &__forgot {
        color: red;
        font-weight: 500;
        cursor: pointer;
        font-size: 12px;
        margin: 0px;
        text-align: right;
      }
    }
    &__message {
      Img {
        width: 50px;
      }
      padding: 15px;
      text-align: center;
      &__p {
        color: #000;
      }
    }
  }
}

.profile {
  padding: 25px;
  &__card {
    padding: 15px;

    &__leftSide {
      padding: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      &__profileImage {
        Img {
          width: 100px;
          height: 90px;
          border: 5px solid #4177b8;
          border-radius: 50%;
          padding-top: 10px;
        }
      }
    }
    &__rightSide {
      padding: 15px;
      position: relative;
      &__edit {
        position: absolute !important;
        right: 0px;
        top: -5px;
      }
    }
  }
}

.contactus {
  &__message {
    width: 80%;
    text-align: center;
  }
  &__banner {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
  }
  &__info {
    background-color: aliceblue;
    margin-bottom: 50px;
    margin-top: 50px;
    border: 8px solid #e4eff9;
  }
  &__formSection {
    &__content {
      padding: 75px 15px !important;
      &__imagesection {
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__form {
        &__submit {
          margin-right: auto;
        }
      }
      &__thankyou{
        text-align: center;
        width: 100%;
        img{
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  &__disclamer {
    padding-top: 50px;
    padding-bottom: 25px;
  }
}

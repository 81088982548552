.import_export_filter {
  margin-top: 25px;
  margin-bottom: 25px;
  // background-color: aliceblue !important;
  // border-radius: 5px !important;
  padding: 25px;
  // border: 5px solid #dceefd;

  &__tableCard {
    background-color: aliceblue !important;
    border: 5px solid #dceefd;
    border-radius: 5px;

    &__data {
      &__download {
        text-align: right;
        width: 100%;
      }

      &__pagination {
        padding: 10px;
        margin-bottom: 40px;

        ul {
          float: right;
        }
      }
    }
  }

  &__graphChart {
    padding: 50px 0px;
  }
}

.MuiMenuItem-root{
  font-size: 12px!important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5.5px 14px!important;
}

.css-1uvydh2{
  padding: 8px 0px 8px 14px!important;
}
input:-internal-autofill-selected{
  height: 0px!important;
}
// .MuiInputBase-formControl{
//   height: 2.2rem;
// }
.MuiInputBase-input{
  font-size: 12px!important;
  height: 10px;
}
.MuiFormControl-root label {
  font-size: 12px !important;
  top: 0px !important;
}
.second_level{
  // height: auto!important;
  display: flex;
  // justify-content: center;
}

.f_level .MuiInputBase-formControl{
  height: 2.2rem!important
}

.f_level label{
  top: -6px !important;
}
li{
  font-size: 12px!important;
}


.buyer {
  &__formSection {
    &__submit {
      display: flex;
      align-items: center;
    }
  }
  &__inputCenter {
    // text-align: center !important;
    width: 50%;
    // margin: auto;
  }
  &__filterCard__form {
    padding: 15px;
  }
}

.header {
  // @include breakpoint(mobileonly) {
  //   padding: 10px 0px;
  // }

  background-color: #6499e9;
  padding-bottom: 64px !important;

  &__logoDiv {
    width: fit-content;
    max-width: fit-content;

    &__logo {
      display: flex;
      padding: 0px 10px;
      &__img{
        height: 45px;
      }
    }
  }

  .MuiBox-root {
    justify-content: center;
  }

  &__link {
    font-size: 12px;
    color: #676767;
    text-decoration: none;
    font-weight: 600;
    padding: 0px 10px;
  }

  &__MenuLink {
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
}

.css-hyum1k-MuiToolbar-root {
  justify-content: space-between !important;
}

div.scroll {
  width: 100%;
  height: 55px;
  overflow-x: hidden;
  overflow-y: auto;
  // white-space: nowrap;
  scrollbar-gutter: stable both-edges;
}
.main {
  padding: 25px;
  padding-top: 50px;
  &__logo {
    width: 100%;
    text-align: center;
    padding: 25px;
    padding-bottom: 50px;

    // border: 2px solid #aaa;
    //   border-radius: 50%;
    //   width: 110px;
    //   height: 110px;

    Img {
      border: 2px solid #aaa;
      border-radius: 50%;
      padding: 10px;
      text-align: center;
      width: 100px;
      height: 100px;
    }
  }
}

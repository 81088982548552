.plan {
  padding-top: 75px;
  padding-bottom: 150px;
  background-color: aliceblue;
  &__header {
    &__section {
      // padding: 25px 0px;
      margin-bottom: 25px;
      &__button {
        width: 100%;
        text-align: left;
        margin-top: 25px;
      }
    }
  }
  &__planName {
    // background-color: map-get($map: $bg-color, $key: secondry);
    // background-image: url("../../assets/images/home-banner.jpg");
    background-color: #4d4e4f;
    color: map-get($map: $text-color, $key: secondry) !important;
    opacity: 0.8;
    margin-top: -120px !important;
    z-index: 9;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900!important;
  }
  &__pricing {
    background-color: map-get($map: $bg-color, $key: secondry);
    color: map-get($map: $text-color, $key: secondry);
    cursor: pointer;
    &__text {
      color: map-get($map: $text-color, $key: secondry) !important;
      padding: 4px;
      font-size: 12px!important;
    }
  }
}
